.menu {
  height: 100%;
  display: flex;
  flex-direction: column;
  .top {
    height: 100%;
  }
  .toggle {
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-right: 1px solid #f0f0f0;
    .icon {
      font-size: 20px;
      cursor: pointer;
      transition: all 0.3s, padding 0s;
      margin-left: 20px;
    }
  }
}
