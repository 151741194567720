@import '~antd/dist/reset.css';

#root {
  width: 100%;
  height: 100%;
}

.loading-wraper {
  width: 100%;
  height: 100%;

  .ant-spin {
    bottom: 0;
    margin: auto;
  }

  .ant-spin-container {
    width: 100%;
    height: 100%;
  }
}

.ql-editor {
  width: 100%;
  height: 100%;
}

/* Turn on custom 8px wide scrollbar */
::-webkit-scrollbar {
  width: 6px; /* 1px wider than Lion. */
  height: 6px;
  /* This is more usable for users trying to click it. */
  background-color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 100px;
}
/* hover effect for both scrollbar area, and scrollbar 'thumb' */
::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
::-webkit-scrollbar-thumb {
  /* This is the EXACT color of Mac OS scrollbars.
     Yes, I pulled out digital color meter */
  background: rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 100px;
  background-clip: border-box;
  border: 2px solid rgba(0, 0, 0, 0);
  min-height: 10px; /*Prevent it from getting too small */
}
::-webkit-scrollbar-thumb:active {
  -webkit-border-radius: 100px;
}

@primary-color: #1890ff;