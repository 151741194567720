:global {
  .ant-pagination {
    margin-top: 20px;
  }
  .ant-table-thead > tr > th {
    font-size: 12px;
  }
  // 表格样式处理
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    font-size: 12px;
    text-align: center;
    padding: 3px 2px !important;
  }

  tr.ant-table-measure-row {
    visibility: collapse;
  }
  .ant-table-cell.ant-table-cell-scrollbar {
    display: none;
  }

  .ant-table-wrapper
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: transparent;
  }
  .ant-table-wrapper .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
  }
  // 表格线
  tbody tr td.color_1 {
    background-color: #f4f4f4 !important;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    border-top: none !important;
  }
  th.border_l,
  td.border_l {
    border-left: 1px solid #ccc !important;
  }
  th.border_r,
  td.border_r {
    border-right: 1px solid #ccc !important;
  }
  th.border_r_666,
  td.border_r_666 {
    border-right: 1px solid #66666696 !important;
  }
  .text_bold,
  td.text_bold {
    font-weight: 700 !important;
  }
  tr.border_b td {
    border-bottom: 1px solid #666;
  }
  .ant-table-wrapper .ant-table-tbody tr.border_t td {
    border-top: 1px solid #666 !important;
  }
  tr.clickRowStyle.border_t td {
    border-top: 1px solid #666 !important;
  }
  tr.border_b_dotted td {
    border-bottom: 1px dotted #666 !important;
  }
  tr.line-bg-yellow td {
    background-color: #fff2ce;
    font-weight: 700;
  }
  tr.line-bg-blue td {
    background-color: #dae2f2;
    font-weight: 700;
  }
  tr.line-bg-pointer td {
    background-color: #dae2f275;
    background-image: radial-gradient(#494b516b 10%, transparent 0);
    background-size: 6px 6px;
    font-weight: 700;
  }
  .ant-table-tbody > .line-bg-yellow:hover > td {
    background-color: #fff2ce !important;
    font-weight: 700;
  }
  .ant-table-tbody > .line-bg-yellow:hover > td.color_1 {
    background-color: #f4f4f4 !important;
  }
  .ant-table-tbody > .line-bg-blue:hover > td {
    background-color: #dae2f2 !important;
    font-weight: 700;
  }
  .ant-table-tbody > .line-bg-blue:hover > td.color_1 {
    background-color: #f4f4f4 !important;
  }
  .ant-table-tbody > .line-bg-pointer:hover > td {
    background-color: #dae2f2 !important;
    background-image: radial-gradient(#494b51 10%, transparent 0) !important;
    background-size: 6px 6px !important;
    font-weight: 700 !important;
    font-weight: 700;
  }
  .ant-table-tbody > .line-bg-pointer:hover > td.color_1 {
    background-color: #f4f4f4 !important;
    background-image: none !important;
    font-weight: 700;
  }

  // 其他样式
  .trBlock {
    display: block;
    cursor: pointer;
    position: relative;
    background-color: rgb(222, 235, 247);
  }
  .commentIcon {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-width: 10px 10px 0 0;
    border-style: solid;
    border-color: #f8c40d transparent transparent transparent;
  }

  th.th_bg_1 {
    background-color: #fefe2c !important;
    font-weight: 700;
  }
  th.th_bg_2 {
    background-color: #f9e3d6 !important;
    font-weight: 700;
  }
  th.th_bg_3,
  td.th_bg_3 {
    background-color: #f9e3d6 !important;
    font-weight: 700;
  }

  th.th_bg_4 {
    background-color: #c7dfb6 !important;
    font-weight: 700;
  }

  th.th_bg_5 {
    background-color: #b5c7e6 !important;
    font-weight: 700;
  }

  th.th_bg_6 {
    background-color: #fafafa !important;
    font-weight: 700;
  }
  td.th_bg_6 {
    background-color: #fafafa !important;
  }

  th.th_bg_7 {
    background-color: #a6d6fc !important;
    font-weight: 700;
  }

  th.th_bg_8 {
    background-color: #9999993d !important;
    font-weight: 700;
  }

  th.th_text_red {
    color: red !important;
  }

  td.td_text_red {
    color: red !important;
  }

  tr.td_bg_green td {
    background-color: #e3efdb !important;
  }
}
