.header {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 0px 15px;
  align-items: center;
  flex-wrap: nowrap;
  background: #001529;
  z-index: 10;
  .username {
    margin-left: 10px;
    cursor: pointer;
    height: 20px;
    line-height: 20px;
    margin-right: 10px;
    color: #fff;
    text-rendering: optimizelegibility;
  }

  .logo {
    height: 64px;
    text-align: center;
    font-size: 16px;
    margin-bottom: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    position: absolute;
    left: 15px;
    top: 0;
    bottom: 0;
    margin: auto;

    .title {
      font-size: 18px;
      color: #fff;
      font-weight: 600;
      line-height: 48px;
      margin: 0px;
    }

    .logoIcon {
      height: 34px;
      margin-right: 16px;
    }
  }
}
