.root {
  width: 100%;
  height: 100%;
  position: relative;
  .header {
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
    padding: 0px;
    height: 48px;
    line-height: 48px;
  }

  .content {
    box-sizing: border-box;
    margin-left: 10px;
    width: 100%;
    height: 100%;
    position: relative;
    overflow-y: scroll;
  }
  .sider{
    z-index: 999;
  }
}
